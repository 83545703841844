var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex mb-4"},[_c('h1',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Agendamentos")]),_c('v-spacer')],1),_c('FilterInput',{attrs:{"dataFilters":[]}}),_c('section',{staticClass:"py-4"},[_c('v-card',{staticClass:"mb-4",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"surface",attrs:{"headers":_vm.headers,"items":_vm.schedules,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.handleStatus(item.status).color,"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.handleStatus(item.status).label)+" ")])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.user.firstName)+" "+_vm._s(item.user.lastName)+" ")]),_c('br'),_c('span',[_vm._v(" "+_vm._s(item.user.phone)+" ")])]}},{key:"item.service",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.schedules[0].item.type.name)+" ")]}},{key:"item.item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.schedules[0].item.name)+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm.moneyMask(item.value))+" ")]}},{key:"item.schedules[0].date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.schedules[0].date))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"rounded-lg mr-2",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.open(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"rounded-lg mr-2",attrs:{"color":"green","icon":"","small":""},on:{"click":function($event){return _vm.approve(item._id)}}},[_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"red","icon":"","small":""},on:{"click":function($event){return _vm.refuse(item._id)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)})],1),_c('v-pagination',{attrs:{"color":"primary","length":_vm.pagination.pageCount,"total-visible":10,"circle":""},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1),_c('v-dialog',{attrs:{"max-width":"500","persistent":""},model:{value:(_vm.scheduleDialog),callback:function ($$v) {_vm.scheduleDialog=$$v},expression:"scheduleDialog"}},[(_vm.scheduleDialog)?_c('v-card',{attrs:{"color":"surface"}},[_c('v-card-title',{staticClass:"pb-0"},[_vm._v(" Selecionados "),_c('v-spacer'),_c('v-btn',{staticClass:"rounded-lg",attrs:{"icon":""},on:{"click":function($event){_vm.scheduleDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('section',{staticClass:"pa-6"},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.selected.schedules),function(item,i){return _c('v-chip',{key:i,staticClass:"mr-2",attrs:{"outlined":""}},[_vm._v(" "+_vm._s(item.identifier.name)+" ")])}),1)],1)],1):_vm._e()],1),(_vm.loading)?_c('loader-hover'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }