<template>
  <v-container fluid>
    <div class="d-flex mb-4">
      <h1 class="text-h5 font-weight-bold">Agendamentos</h1>

      <v-spacer />

      <!-- <v-btn text @click="manage()">
        Gerenciar <v-icon right> mdi-cog</v-icon>
      </v-btn> -->
    </div>

    <!-- <FilterGroup class="mb-4" :filters="types" :queryKey="'type'" /> -->

    <FilterInput :dataFilters="[]" />

    <section class="py-4">
      <v-card class="mb-4" outlined>
        <v-data-table
          class="surface"
          :headers="headers"
          :items="schedules"
          hide-default-footer
        >
          <!-- status -->
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="handleStatus(item.status).color" dark small>
              {{ handleStatus(item.status).label }}
            </v-chip>
          </template>

          <!-- user -->
          <template v-slot:[`item.user`]="{ item }">
            <span>
              {{ item.user.firstName }}
              {{ item.user.lastName }}
            </span>

            <br />

            <span>
              {{ item.user.phone }}
            </span>
          </template>

          <!-- service -->
          <template v-slot:[`item.service`]="{ item }">
            {{ item.schedules[0].item.type.name }}
          </template>

          <!-- item -->
          <template v-slot:[`item.item`]="{ item }">
            {{ item.schedules[0].item.name }}
          </template>

          <!-- date -->
          <template v-slot:[`item.value`]="{ item }">
            R$ {{ moneyMask(item.value) }}
          </template>

          <!-- date -->
          <template v-slot:[`item.schedules[0].date`]="{ item }">
            {{ formatDate(item.schedules[0].date) }}
          </template>

          <!-- date -->
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <!-- actions -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn class="rounded-lg mr-2" icon small @click="open(item)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>

            <v-btn
              class="rounded-lg mr-2"
              color="green"
              icon
              small
              @click="approve(item._id)"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>

            <v-btn
              class="rounded-lg"
              color="red"
              icon
              small
              @click="refuse(item._id)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>

      <!-- pagination -->
      <v-pagination
        v-model="pagination.page"
        color="primary"
        :length="pagination.pageCount"
        :total-visible="10"
        circle
        @input="getData()"
      />
    </section>

    <!-- dialog -->
    <v-dialog v-model="scheduleDialog" max-width="500" persistent>
      <v-card v-if="scheduleDialog" color="surface">
        <v-card-title class="pb-0">
          Selecionados <v-spacer />
          <v-btn class="rounded-lg" icon @click="scheduleDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <section class="pa-6">
          <v-row no-gutters>
            <v-chip
              v-for="(item, i) in selected.schedules"
              class="mr-2"
              :key="i"
              outlined
            >
              {{ item.identifier.name }}
            </v-chip>
          </v-row>
        </section>
      </v-card>
    </v-dialog>

    <loader-hover v-if="loading" />
  </v-container>
</template>

<script>
import {
  getSchedules,
  approveSchedule,
  refuseSchedule,
} from "@/services/schedules";
import { displayAlert, moneyMask, formatDate } from "@/utils";
import FilterGroup from "@/components/filters/FilterGroup";
import FilterInput from "@/components/filters/FilterInput";

export default {
  data() {
    return {
      loading: false,
      interval: null,
      scheduleDialog: false,
      selected: null,
      types: [
        { label: "Todos", value: "" },
        { label: "Corujão", value: "corujao" },
        { label: "Sala VIP", value: "vip" },
        { label: "Sala de Stream", value: "stream" },
        { label: "Eventos", value: "eventos" },
      ],
      headers: [
        { text: "Status", value: "status" },
        { text: "Cliente", value: "user" },
        { text: "CPF", value: "pix.cpf" },
        { text: "Código", value: "code" },
        { text: "Serviço", value: "service" },
        { text: "Item", value: "item" },
        { text: "Valor", value: "value" },
        { text: "Agendamento", value: "schedules[0].date" },
        { text: "Criação", value: "createdAt" },
        { text: "Ações", value: "actions" },
      ],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      schedules: [],
    };
  },

  components: {
    FilterGroup,
    FilterInput,
  },

  /* watch: {
    ["$route.query"]() {
      this.loading = true;
      this.pagination.page = 1;

      this.getData();
    },
  }, */

  beforeMount() {
    this.getData();

    this.interval = setInterval(() => {
      this.getData();
    }, 5000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    async getData() {
      try {
        const payload = {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          ...this.$route.query,
        };

        await getSchedules(payload).then((res) => {
          this.schedules = res.content;
          this.pagination.pageCount = res.totalPages;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async approve(value) {
      try {
        this.loading = true;

        const payload = {
          _id: value,
        };

        await approveSchedule(payload).then((res) => {
          this.displayAlert("Aprovado com sucesso");
          this.getData();
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async refuse(value) {
      try {
        this.loading = true;

        const payload = {
          _id: value,
        };

        await refuseSchedule(payload).then((res) => {
          this.displayAlert("Rejeitado com sucesso");
          this.getData();
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    open(data) {
      this.selected = data;
      this.scheduleDialog = true;
    },

    handleStatus(value) {
      switch (value) {
        case "AWAITING":
          return {
            label: "Aguardando",
            color: "amber",
          };
        case "PAID":
          return {
            label: "Aprovado",
            color: "green",
          };
        case "CANCELED":
          return {
            label: "Cancelado",
            color: "red",
          };
        case "TIMEOUT":
          return {
            label: "Expirado",
            color: "red",
          };
        default:
          return {
            label: "Carregando",
            color: "subtext",
          };
      }
    },

    manage() {
      this.$router.push({ path: "/schedules/manage" });
    },

    displayAlert,

    moneyMask,

    formatDate,
  },
};
</script>

<style>
</style>